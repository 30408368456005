import React from 'react';
import theme from '../../src/theme';
import Link from 'gatsby-link';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FaFacebook, FaTwitter, FaYoutube, FaTelegram, FaInstagram, FaSpotify } from 'react-icons/fa';

const useStyles = makeStyles(theme => ({
  boxIconSocial:{
    margin: "0 0.5rem",
  },
  barraSocial:{
    display: "flex",
    // [theme.breakpoints.up("md")]: {
    //   flexDirection: 'column',
    //   position: "absolute",
    //   right: "5%",
    //   top: "40%",
    // },
    [theme.breakpoints.down("sm")]: {
      marginTop:"0.5rem",
    }
  },
  iconSocial:{
    fontSize: "1.75rem",
    color: "#9F9E9E",
    textDecoration: "none",
  },
  contacto: {
    margin: "2.5rem 0.5rem 0rem 0rem",
    backgroundColor: "#9F9E9E",
    color: '#F5F5F5',
    padding: "0.3rem 0.6rem",
    fontWeight: 600,
    fontSize: "0.6rem",
    letterSpacing: '0.1rem',
    borderRadius: "8%",
    fontFamily:"FuturaBold",
  },
}));

const Social = (matches) => {
  const classes = useStyles(theme);
  return(
    <>
        <Box className={classes.barraSocial}>
          <div style={{ display: 'flex' }}>
            <Box className={classes.boxIconSocial}>
              <a className={classes.iconSocial} target="_blank" rel="noopener norefferer" href="https://www.facebook.com/PoliticaObrera/">
                <FaFacebook />
              </a>
            </Box>
            <Box className={classes.boxIconSocial}>
              <a className={classes.iconSocial} target="_blank" rel="noopener norefferer" href="https://twitter.com/politicaobrera_">
                <FaTwitter />
              </a>
            </Box>
            <Box className={classes.boxIconSocial}>
              <a className={classes.iconSocial} target="_blank" rel="noopener norefferer" href="https://instagram.com/politicaobrera_">
                <FaInstagram />
              </a>
            </Box>        
            <Box className={classes.boxIconSocial}>
              <a className={classes.iconSocial} target="_blank" rel="noopener norefferer" href="https://www.youtube.com/channel/UCHtrgacI-14jCFa197-4wHQ">
                <FaYoutube />
              </a>
            </Box>
            <Box className={classes.boxIconSocial}>
              <a className={classes.iconSocial} target="_blank" rel="noopener norefferer" href="https://t.me/politicaobrera">
                <FaTelegram />
              </a>
            </Box>
            <Box className={classes.boxIconSocial}>
              <a className={classes.iconSocial} target="_blank" rel="noopener norefferer" href="https://spoti.fi/3EZGbBw">
                <FaSpotify />
              </a>
            </Box>
          </div>
          {/* {!celu && (
            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Link style={{ textDecoration: 'none' }} key="link-contacto" className={classes.boxSeccion} to="/contacto">
                <span className={classes.contacto}>CONTACTO</span>
              </Link>
            </Box>
          )} */}
        </Box>
    </>
  );
}

export default Social;
