import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, useStaticQuery } from "gatsby"
import Link from 'gatsby-link';

const { slug } = require('../utils/tools.js');
const useStyles = makeStyles(theme => ({
  item:{
    display: "inline",
    margin: "0 .6rem",
    fontWeight: "900",
    fontSize: ".7rem",
    fontFamily: "FuturaBold",
    textTransform: "uppercase",
    textDecoration: "none",
    whiteSpace: "nowrap",
    color: theme.palette.thirty,
    [theme.breakpoints.down('md')]: {
      display: "inline-block",
    }
  },
  menu: {
    borderBottom: `2px solid ${theme.palette.thirty}`,
    borderTop: `2px solid ${theme.palette.thirty}`,
    padding: ".5rem 0",
    textAlign: "center"
  }
}));

const SeccionesFooter = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          secciones
        }
      }
    }
  `);
  const secciones = data.site.siteMetadata.secciones;

  return (
    <Box my={2} py={1}>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Box className={classes.menu}>
            {secciones.map(item => (
              <Link key={`link-${item}`} className={classes.item} to={`/seccion/${slug(item)}`}>
                {item}
              </Link>
            )
            )}
            <a target="_blank" href="https://altamiraresponde.com/" rel="noopener" className={classes.item}>
              {'Altamira Responde'}
            </a>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SeccionesFooter;
