import React, { useState } from 'react';
import { Container, Grid, Box, Icon } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Link from 'gatsby-link';
import Social from './Social';
import SearchInput from './SearchInput';
const { slug } = require('../utils/tools.js');

const useStyles = makeStyles(theme => ({
  containerSecciones:{
    borderBottom:'2px solid black',
    borderTop:'2px solid black',
    [theme.breakpoints.down('sm')]: {
      height:"2.7rem",
      overflow:"hidden",
    },
  },
  containerSeccionesScrolled:{
    [theme.breakpoints.down('sm')]: {
      height:'2.5rem',
      position:'fixed',
      top:'0',
      backgroundColor:theme.palette.background.default,
      zIndex:'100',
      marginTop:'0rem !important',
      borderTop:'none',
      left: '0',
      right: '0'
    },
  },
  containerExpand:{
    [theme.breakpoints.down('sm')]: {
      height:"auto",
      overflow:"visible",
    },
  },
  filaSecciones:{
    padding: '.2rem 0',
    display:"flex",
    flexWrap:"wrap",
    alignItems: "center",
    justifyContent: "center",
    width: '60%',
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      width: '71%'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection:"column",
      textAlign: 'left',
      marginTop:"0",
    },
  },
  boxSeccion:{
    display:"inline-block",
    fontSize:".9rem",
    fontFamily:"FuturaBold",
    textDecoration:"none",
    color:"black",
    margin: "0 .7rem",
    padding: ".2rem 0",
    whiteSpace: "nowrap"
  },
  boxSeccion__Altamira:{
    color: theme.palette.base,
  },
  labelNew:{
    fontSize: "0.5rem",
    color: theme.palette.base,
  },
  labelRevistaSeccion:{
    color: "white",
    backgroundColor:"#E5332A",
    padding:"0.1rem 0.5rem 0rem 0.5rem",
    width:"11.9rem",
  },
  labelTrotsky80Seccion:{
    color: 'black',
    backgroundColor:"yellow",
    // padding:"0.1rem 0.5rem 0rem 0.5rem",
    padding: '2px 0px 0px 2px',
    border: '2px solid black',
  },
  boxRevistaSeccion: {
    display:"flex",
    flexDirection:"column",
    textAlign: 'left',
  },
  expanded:{
    margin:"0 auto",
    fontSize:"2rem !important",
    transform: 'rotate(180deg)',
    visibility: 'hidden',
    [theme.breakpoints.down('sm')]: {
      visibility: 'visible',
      transition: 'height 3000ms ease-out',
    },
  },
  collapsed:{
    visibility: 'hidden',
    fontSize:"2rem !important",
    [theme.breakpoints.down('sm')]: {
      visibility: 'visible',
    },
  },
  scrolled:{
    visibility:'hidden',
  },
  boxExpanded:{
    height:"0",
    [theme.breakpoints.down('sm')]: {
      display:"flex",
      justifyContent:"center",
      height:"auto",
    },
  },
  contacto: {
    //padding: "1rem 0rem 0rem 0rem",
    color: '#9F9E9E',
    //padding: "0.3rem 0.6rem",
    fontWeight: 600,
    fontSize: "1rem",
    letterSpacing: '0.05rem',
    //borderRadius: "8%",
    fontFamily:"FuturaBold",
  },
  new:{
    backgroundColor: 'yellow',
    border: '2px solid black',
    padding: '3px 3px 0px 3px',
    // transition: 'all .2s ease-in-out',
    // animation-iteration-count:infinite;
    // '&:hover':{
    //   backgroundColor: 'red',
    //   borderStyle: 'dotted',
    // },
    // borderTop: '3px solid black',
    // borderBottom: '3px solid black',
    // borderRight: '3px solid black', 
  },
  animatedItem: {
    animationIterationCount: 'infinite',
    animation: `$myEffect 2500ms ${theme.transitions.easing.easeInOut}`,
  },
  // animatedItemExiting: {
  //   animation: `$myEffectExit 3000ms ${theme.transitions.easing.easeInOut}`,
  //   opacity: 0,
  //   transform: "translateY(-200%)",
  // },
  "@keyframes myEffect": {
    "0%": {
      opacity: 0,
      transform: "translateY(-200%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },
  social:{
    [theme.breakpoints.up('md')]: {
      order: 1,
    },
  },
  // "@keyframes myEffectExit": {
  //   "0%": {
  //     opacity: 1,
  //     transform: "translateY(0)",
  //   },
  //   "100%": {
  //     opacity: 0,
  //     transform: "translateY(-200%)",
  //   },
  // },
}));

// {` `}<span className={classes.triangulin}>{``}</span>

const BarraSecciones = ({ scrolled, secciones, matches, esPaginaBusqueda }) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const handleCollapse = e => {
    setExpanded(!expanded);
  };

  const menuClassName = [classes.containerSecciones];
  if (expanded) {
    menuClassName.push(classes.containerExpand);
  }
  if (scrolled) {
    menuClassName.push(classes.containerSeccionesScrolled);
  }

  return (
    <Container maxWidth="xl">
      <Grid item xs={12}>
        <Box my={2} py={1} className={menuClassName.join(' ')}>
          {!expanded && <Box className={classes.boxExpanded}>
            <Icon onClick={handleCollapse} className={expanded ? classes.expanded : classes.collapsed}>keyboard_arrow_down</Icon>
          </Box>
          }
          <Grid container className={classes.grillaSecciones}>
            <Grid item xs={12} md={12}>
              <div className={classes.filaSecciones}>
                {!esPaginaBusqueda && <SearchInput />}
                {/* <Link key="link-guerra" className={classes.boxSeccion} to="/tag/guerra-mundial-documentacion">
                  <Box className={classes.boxRevistaSeccion} style={{whiteSpace: 'initial', textAlign: 'center' }}>
                    <span className={classes.labelRevistaSeccion}>La Guerra Mundial Documentación</span>
                  </Box>
                </Link> */}
                {/* <Link key="link-congreso" className={classes.boxSeccion} to="/seccion/congreso-politica-obrera">
                  <Box className={`${classes.boxRevistaSeccion} ${classes.labelTrotsky80Seccion}`} style={{ width: '250px', whiteSpace: 'initial', textAlign: 'center' }}>
                    <div>
                      <span>Congreso de Política Obrera</span>
                    </div>
                  </Box>
                </Link> */}
                {secciones.map(item => (
                  <Link key={`link-${item}`} className={classes.boxSeccion} to={`/seccion/${slug(item)}`}>
                    {item}
                  </Link>
                ))}
                <a className={`${classes.boxSeccion} ${classes.boxSeccion__Altamira}`} target="_blank" rel="noopener norefferer" href="https://www.youtube.com/channel/UCO4sLsIfvZqhI66R2_f-0MA/videos">
                  {'ALTAMIRA RESPONDE'}
                </a>
                <Link key="link-revista" className={classes.boxSeccion} to="/revista">
                  <Box className={classes.boxRevistaSeccion}>
                    <span className={classes.labelRevistaSeccion}>Política Obrera Revista</span>
                  </Box>
                </Link>
                {/* <Link key="link-80-anos-trotsky" className={classes.boxSeccion} to="/leon-trotsky-80-anos">
                  <Box className={classes.boxRevistaSeccion}>
                    <span className={classes.labelTrotsky80Seccion}>León Trotsky 80 años</span>
                  </Box>
                </Link> */}
                
                <Link key="link-contacto" className={classes.boxSeccion} to="/contacto">
                  <Box>
                    <span className={classes.contacto}>CONTACTO</span>
                  </Box>
                </Link>
                
                <Social matches={true} className={classes.social}/>
              </div>
            </Grid>
          </Grid>
          {expanded && <Box className={classes.boxExpanded}>
            <Icon onClick={handleCollapse} className={expanded ? classes.expanded : classes.collapsed}>keyboard_arrow_down</Icon>
          </Box>
          }
        </Box>
      </Grid>
    </Container>
  );
};

export default BarraSecciones;
