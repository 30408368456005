import React, {useState, useEffect} from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import desktopSVG from '../assets/logo/politica-obrera.svg'
import mobileSVG from '../assets/logo-mobile/politica-obrera.svg'
import { graphql, useStaticQuery } from "gatsby"
import Link from 'gatsby-link';
import Social from './Social';
import BarraSecciones from './BarraSecciones';
import withLocation from "../HOCS/WithLocation"

// import Search from "./Search"
import SearchInput from './SearchInput';
const useStyles = makeStyles(theme => ({
  header:{
    position:'relative',
    padding: "1.2rem 0 1rem 0",
    marginBottom: "-1rem",
    [theme.breakpoints.down('sm')]: {
      backgroundColor: "#f5f5f5",
      alignItems: "center",
    }
  },
  logo: {
    maxWidth: "14rem",
    display:"flex",
    margin:"0 auto",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "19rem",
    }
  },
  boxSearch:{
    [theme.breakpoints.up("md")]: {
      position:"absolute",
      left:"5%",
      top:"40%"
    },
  },
}));

const Header = props => {
  const classes = useStyles();
  const [scrolled, setScrolled] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          secciones
        }
      }
      mobileImage: file(sourceInstanceName: { eq: "logo-mobile" }) {
        childImageSharp {
          fixed(height: 55, width: 200, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      desktopImage: file(sourceInstanceName: { eq: "logo" }) {
        childImageSharp {
          fixed(height: 118, width: 172, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  const secciones = data.site.siteMetadata.secciones;

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY < 215 && scrolled) {
        setScrolled(false);
      }
      else if (window.scrollY >= 215 && !scrolled) {
        setScrolled(true);
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { location } = props
  const esPaginaBusqueda = location.pathname === "/busqueda" 
  //const source = matches ? data.mobileImage.childImageSharp.fixed : data.desktopImage.childImageSharp.fixed;
  
  return (
    <Box>
      <Box className={classes.header}>
        {/* {!matches && !esPaginaBusqueda && <Box className={classes.boxSearch}><SearchInput /></Box>} */}
        <Link className={classes.logo} key="home-link" to={`/`}>
          {/* <Img fixed={source} /> */}
          <img src={mobileSVG} alt="logo" />
          {/* {!matches && <img src={desktopSVG} alt="logo" />} */}
        </Link>
        {/* {!matches && <Social matches={matches} />} */}
      </Box>
      <BarraSecciones esPaginaBusqueda={esPaginaBusqueda} scrolled={scrolled} secciones={secciones} matches={matches} />
    </Box>
  );
};

export default withLocation(Header);