function slug(str) {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
    
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str
      .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
      .replace(/\s+/g, '-') // collapse whitespace and replace by -
      .replace(/-+/g, '-'); // collapse dashes
    return str;
  };

  function autorModifier (autor) {
/*     let autorM = ""
    switch (autor) {
      case "Jorge Altamira":
        autorM = "Jorge Altamira (Candidato a Diputado por la Ciudad de Buenos Aires)"
        break
      case "Marcelo Ramal":
        autorM = "Marcelo Ramal (Candidato a Presidente)"
        break
      case "Valentina Viglieca":
        autorM = "Valentina Viglieca (Candidata a Jefa de Gobierno por la Ciudad de Buenos Aires)"
        break
      case "Patricia Urones":
        autorM = "Patricia Urones (Precandidata a Vicepresidenta)"
        break
      case "Mariano Hermida":
        autorM = "Mariano Hermida (precandidato a Intendente)"
        break;
      case "Silvia Allocati ":
        autorM = "Silvia Allocati (precandidata a legisladora porteña)"
        break;
      default:
        autorM = autor
        break;
    }
    return autorM */
    return autor
  }

  function getCadenaAutores(arrayAutores) {
    if ( arrayAutores && arrayAutores.length) {
      const cant = arrayAutores.length;
        // console.log("cadena",arrayAutores);
        return arrayAutores.map((item,index) => {
          if(index === 0){
            //primero
            return `${autorModifier(item)}`;
          }
          if((index + 1) === cant){
            //el ultimo
            return ` y ${autorModifier(item)}`;
          }
          return `, ${autorModifier(item)}`
        }).join('');
    }
    return "";
  }
  function getArrayAutores(arrayAutores) {
    return arrayAutores.map(item => {
      return {'autor': item, 'slug': slug(item)}
    });
  }

  module.exports.slug = slug
  module.exports.getCadenaAutores = getCadenaAutores
  module.exports.getArrayAutores = getArrayAutores
  module.exports.autorModifier = autorModifier
