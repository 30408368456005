import { createMuiTheme, responsiveFontSizes, colors } from "@material-ui/core";

import FuturaStdBoldWoff2 from './assets/fonts/FuturaStd-Bold.woff2';

const futura = {
  fontFamily: 'FuturaBold',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'bold',
  src: `
    local('Futura-Bold'),
    url(${FuturaStdBoldWoff2}) format('woff2')
  `,
  unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = createMuiTheme({
  palette: {
    base: '#ff0000',
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    thirty: '#9F9E9E',
    error: {
      main: colors.red.A400,
    },
    background: {
      default: '#F5F5F5',
    },
    archivo: '#e6df18',
    economia: '#7552a1',
    historia: '#8b4c20',
    internacionales: '#42bfef',
    movimiento: '#f78f1e',
    mujer: '#11a24a',
    politicas: '#ef3a24',
    sociedad: '#3a5aa8',
    juventud: '#b84609',
    correo: '#515150',
    debate: '#D59A00',
    declaraciones: '#ffa4d0',
  },
  // typography: {
  //   fontFamily: 'FuturaBold, Arial',
  // },
  overrides: {
    MuiCard: {
      root: {
        boxShadow: "none",
        backgroundColor: "transparent",
      }
    },
    MuiCssBaseline: {
    '@global': {
      '@font-face': [futura],
      '*::-webkit-scrollbar': {
        width: '5px',
        borderRadius: '5px',
        backgroundColor: 'white',
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '*::-webkit-scrollbar-thumb': {
        // outline: '1px solid slategrey'
        borderRadius: '5px',
        backgroundColor: 'black',
      }
    }
   }
  }
});

export default responsiveFontSizes(theme);
