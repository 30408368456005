import React from 'react';
import { Box, Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Img from "gatsby-image/withIEPolyfill"
import { graphql, useStaticQuery } from "gatsby";
import SeccionesFooter from './SeccionesFooter';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  row: {
    borderTop: "1px solid black",
    height: 100
  },
  image: {
    maxWidth: 120,
    height: 90,
    margin: "1rem auto"
  }
}));

const Footer = () => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: {eq: "logo-footer"}){
        childImageSharp {
          fluid (maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const fluid = data.file.childImageSharp.fluid;
  return (
    <Container maxWidth="xl">
      <Grid item xs={12}>
        <SeccionesFooter />
        <Box className={classes.image}>
          <Img fluid={fluid} />
        </Box>
      </Grid>
    </Container>
  );
};

export default Footer;
