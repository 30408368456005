import React from 'react';
import { Helmet } from 'react-helmet';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';

import theme from '../theme';
import Footer from './Footer';
import Header from './Header';

export default function Layout(props) {
  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
        />
        <meta property="fb:app_id" content="199305801968053" />
        {/* <script async={true} defer={true} crossOrigin="anonymous" nonce="w3C3VnVh" src="https://connect.facebook.net/es_LA/sdk.js#xfbml=1&version=v10.0&appId=199305801968053&autoLogAppEvents=1" /> */}
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header />
        {props.children}
        <Footer />
      </ThemeProvider>
    </React.Fragment>
  );
}