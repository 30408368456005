import React, { Component } from "react"
import { navigate } from 'gatsby';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchSharp from '@material-ui/icons/SearchSharp';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
      '& .MuiInput-underline:after': {
        borderBottomColor: 'red',
      },
      backgroundColor: "#ececec",
      '& .MuiInput-input': {
        fontSize: "larger",
      },
      marginBottom: "0.7rem",
    },
})

class SearchInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      term: ''
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const { pathname } = window.location; 
    navigate(`/busqueda?q=${this.state.term}`, { state : { term : this.state.term } });
  }

  onChange = (event) => {
    this.setState({
      term: event.target.value
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <form onSubmit={this.handleSubmit.bind(this)} noValidate autoComplete="off">
          <TextField
            name="term"
            type="search"
            placeholder="Buscar"
            // variant="filled"
            className={classes.root}
            value={this.state.term}
            onChange={this.onChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchSharp />
                </InputAdornment>
              ),
            }}
          />
        </form>
      </div>
    )
  }
}

export default withStyles(styles)(SearchInput)